import React, { useRef, useState, useEffect } from "react"
import { Link, navigate } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { useAuth } from "../components/context/auth"
import { CubeTransparentIcon } from "@heroicons/react/outline"
import uniqueSlug from "unique-slug"
import store from "store"
import { useForm } from "react-hook-form"
import { useMutation } from "react-query"
import * as api from "../components/context/api"
import Joi from "joi"
import _ from "lodash"

const Register = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()
  const { signup, loginWithGoogle, updatePassword, currentUser, logout } =
    useAuth()
  const emailRef = useRef()
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState(null)
  const [inputs, setInputs] = useState(null)
  const [step, setStep] = useState("step1")

  // const logoutUser = async () => {
  //   await logout()
  // }

  // useEffect(() => {
  //   logoutUser()
  // }, [])

  const schema = Joi.object({
    email: Joi.string().email({
      minDomainSegments: 2,
      tlds: { allow: ["com", "in"] },
    }),
  })

  const onSubmitStep1 = async e => {
    e.preventDefault()
    try {
      setLoading(true)
      const email = emailRef.current.value.trim()
      let validation = schema.validate({ email: email })
      if (validation.error) {
        setError("Invalid Email")
        setLoading(false)
        return
      }
      await signup(email, uniqueSlug(email))
      setEmail(email)
      setStep("step2")
      setError("")
      setLoading(false)
    } catch (err) {
      setError(err.message)
      setLoading(false)
    }
  }

  const onSubmitStep2 = formData => {
    setLoading(true)
    let names = SplitName(formData.name)
    let value = {
      ...names,
      mobileNo: formData.mobile,
      password: formData.password,
      email: email,
      studentId: uniqueSlug(email),
    }
    setInputs(value)
    // console.log(value)
    mutation.mutate(value)
  }

  const mutation = useMutation(api.postStudent, {
    onSuccess: async result => {
      const user = _.pick(result.data, [
        "firstName",
        "lastName",
        "studentId",
        "email",
      ])
      store.set("user", user)
      if (step === "step2") {
        await updatePassword(inputs?.password)
      }
      navigate("/app/home", { replace: true })
      setLoading(false)
    },
    onError: () => {
      setError("Error, please try again")
      setLoading(false)
    },
  })

  const googleAuthHandler = async e => {
    try {
      const result = await loginWithGoogle()

      setLoading(true)
      let names = SplitName(result.user.displayName)
      let value = {
        ...names,
        mobileNo: result.user.phoneNumber,
        email: result.user.email,
        studentId: uniqueSlug(result.user.email),
      }
      setInputs(value)
      mutation.mutate(value)
      // navigate("/app/home", { replace: true })
    } catch (err) {
      setError("Failed to create an account")
    }
  }

  return (
    <div className="flex items-center justify-center w-full h-screen mx-auto ">
      <div className="w-full max-w-sm p-6 m-auto bg-white md:shadow rounded-xl dark:bg-gray-800">
        <h1 className="hidden text-3xl font-semibold text-center lg:block text-skin-inverted dark:text-white">
          <a href="https://acadmi.in">acadmi</a>
        </h1>
        <h1 className="text-3xl font-semibold text-center lg:hidden text-skin-inverted dark:text-white">
          acadmi
        </h1>
        {step === "step1" && (
          <>
            <p className="mt-5 text-xl text-center">Create your Free Account</p>
            <GoogleAuth googleAuthHandler={googleAuthHandler} />
            <form className="mt-6 " onSubmit={onSubmitStep1}>
              <div>
                <label
                  htmlFor="username"
                  className="block text-sm text-gray-800 dark:text-gray-200"
                >
                  Email
                </label>
                <input
                  type="text"
                  ref={emailRef}
                  required
                  className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
                />
              </div>
              {error && <p className="mt-5 text-sm text-red-400">{error}</p>}
              <FormButton loading={loading} title="Register" />
            </form>
          </>
        )}
        {step === "step2" && (
          <form className="mt-6 " onSubmit={handleSubmit(onSubmitStep2)}>
            <div className="p-3 mb-8 text-center text-green-500 rounded-xl bg-green-50">
              Account Created
              <p className="text-xs text-black">
                Please complete your profile...
              </p>
            </div>
            <InputField
              label="Name"
              name="name"
              type="text"
              register={register}
              args={{
                required: true,
                minLength: 3,
              }}
            />
            {errors.name && (
              <p className="mt-1 text-xs text-red-500">Invalid name</p>
            )}

            <InputField
              label="Mobile"
              name="mobile"
              type="number"
              register={register}
              args={{
                required: true,
                valueAsNumber: true,
                min: 5000000000,
                max: 9999999999,
              }}
            />
            {errors.mobile && (
              <p className="mt-1 text-xs text-red-500">Invalid Mobile No.</p>
            )}

            <InputField
              label="Create a Password"
              name="password"
              type="password"
              register={register}
              args={{
                required: true,
                minLength: 6,
              }}
            />
            {errors.password && (
              <p className="mt-1 text-xs text-red-500">Invalid password</p>
            )}

            {error && <p className="mt-5 text-red-400">{error}</p>}
            <FormButton loading={loading} title="Continue" />
          </form>
        )}

        {step === "step1" && (
          <p className="mt-8 font-light text-center text-gray-400">
            <Link
              to={`/login`}
              href="#"
              className="ml-2 font-medium text-gray-800 dark:text-gray-200 hover:underline"
            >
              Login
            </Link>
          </p>
        )}
      </div>
    </div>
  )
}

const InputField = ({ label, name, type, args, register }) => {
  return (
    <div className="mt-4">
      <label
        htmlFor={label}
        className="block text-sm text-gray-800 dark:text-gray-200"
      >
        {label}
      </label>

      <input
        type={type}
        {...register(name, args)}
        className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-500 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring-1"
      />
    </div>
  )
}

function FormButton({ loading, title }) {
  return (
    <div className="mt-6">
      <button
        disabled={loading}
        className="w-full py-5 btn disabled:opacity-70"
      >
        <div className="flex justify-center text-center">
          <CubeTransparentIcon
            className={`${loading ? "animate-spin w-5 h-5 mr-2" : "hidden"}`}
          />{" "}
          {title}
        </div>
      </button>
    </div>
  )
}

function GoogleAuth({ googleAuthHandler }) {
  return (
    <>
      <div className="flex items-center mt-6 ">
        <button
          type="button"
          onClick={googleAuthHandler}
          className="flex items-center justify-center w-full px-6 py-2 text-sm font-medium transition-colors duration-200 transform bg-gray-100 border rounded-md hover:bg-gray-200 focus:outline-none"
        >
          <StaticImage
            src="../images/google.png"
            alt="google logo"
            className="w-4 h-4"
            placeholder={"tracedSVG"}
          />

          <span className="mx-2">Sign up with Google</span>
        </button>
      </div>
      <div className="flex items-center justify-center m-2 text-gray-400">
        or
      </div>
    </>
  )
}

export function SplitName(name) {
  const arr = name.split(/ (.*)/)
  return {
    firstName: arr[0],
    lastName: arr[1] || "",
  }
}

export default Register
